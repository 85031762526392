<template>
  <div>
    <b-card title="Bem-vindo ao ConsigPrev!">
      <b-card-text>
        Projetado pela Agenda Assessoria, nosso sistema de consignação promove praticidade, produtividade e precisão para o
        progresso do seu negócio.
      </b-card-text>
      <b-card-text
        >O manual de introdução ao sistema está disponível em nossa área de apoio.
        <b-link id="linkCentralApoio" :to="{ name: 'centralapoio' }"> Clique aqui. </b-link>
      </b-card-text>
    </b-card>

    <CardStatistic v-if="show" />

    <b-modal
      id="modal-selecionar-instituto"
      ref="modal-selecionar-instituto"
      centered
      size="sm"
      title="Unidade Gestora"
      hide-footer
      no-close-on-backdrop
      hide-header-close
      no-close-on-esc
    >
      <SelecionarInstituto @selecionarInstitutoEvent="selecionarInstitutoMethod" />
    </b-modal>
  </div>
</template>

<script>
  import SelecionarInstituto from './components/selecionarInstituto.vue'
  import CardStatistic from './pages/statistic/CardStatistic.vue'
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarData } from '@/libs/utils'

  export default {
    components: {
      CardStatistic,
      SelecionarInstituto,
      formatarData,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        itensCarregados: JSON.parse(sessionStorage.getItem('itensCarregados')),
        show: false,
        userDataComInstituto: null,
        isLoaded: false,
      }
    },
    created() {},
    async mounted() {
      await this.configuraModalSelecionarInstituto()
      await this.atualizarIndices()
      this.show = true
    },
    methods: {
      async configuraModalSelecionarInstituto() {
        const role = this.userData.role
        const institutosVinculados = this.userData.institutosVinculados.length
        const institutoSelecionado = this.userData.institutoSelecionado

        // Configura master
        if (role == 'master' && !institutoSelecionado) {
          this.$refs['modal-selecionar-instituto'].show()
          return
        }

        // Configura admin
        if (role == 'admin' && institutosVinculados > 1 && !institutoSelecionado) {
          this.$refs['modal-selecionar-instituto'].show()
          return
        }

        if (role != 'master') {
          // Configura admins solo e user
          await this.carregarInstituto()
          if (!this.itensCarregados) {
            const itensCarregados = true
            sessionStorage.setItem('itensCarregados', JSON.stringify(itensCarregados))
            if (this.isLoaded && institutosVinculados == 1 && !institutoSelecionado) {
              this.$root.$emit('atualizarItemsMenu', true)
              this.$router.go()
            }
          } else {
            return
          }
        }
      },
      selecionarInstitutoMethod(data) {
        if (data) {
          this.show = true
          this.$refs['modal-selecionar-instituto'].hide()
        }
      },

      async carregarInstituto() {
        const dadosParaAtualizar = {
          userData: this.userData,
          institutoId: this.userData.Instituto.id,
          grupoAcessoId: this.userData.GrupoAcesso.id,
        }

        const updatedUserData = (await useJwt.post('auth/GetAtualizaUserData', dadosParaAtualizar)).data
        localStorage.setItem('userData', updatedUserData)
      },
      async atualizarIndices() {
        useJwt
          .post(`configuracoes/indices/PostAtualizaIndices`, { institutoId: this.userData.institutoSelecionado })
          .catch((error) => {
            console.error(error)
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
  body.dark-layout #linkCentralApoio {
    color: #007bff !important;
    cursor: pointer !important;
  }
</style>
