<template>
  <div>
    <b-row>
      <b-col lg="12">
        <card-statistics-group />
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6">
        <card-statistic-profit-chart />
      </b-col>
      <b-col lg="6">
        <card-statistic-order-chart />
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { kFormatter } from '@core/utils/filter'
  import CardStatisticOrderChart from './CardStatisticOrderChart.vue'
  import CardStatisticProfitChart from './CardStatisticProfitChart.vue'
  import CardStatisticsGroup from './CardStatisticsGroup.vue'

  export default {
    components: {
      CardStatisticOrderChart,
      CardStatisticProfitChart,
      CardStatisticsGroup,
    },
    data() {
      return {
        // Area charts
        subscribersGained: {},
        revenueGenerated: {},
        quarterlySales: {},
        ordersRecevied: {},

        // Line Charts
        siteTraffic: {},
        activeUsers: {},
        newsletter: {},
      }
    },
    created() {},
    methods: {
      kFormatter,
    },
  }
</script>
