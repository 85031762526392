<template>
  <b-card>
    <b-row>
      <b-col md="6">
        <b-card body-class="pb-50">
          <h5>Valor total das propostas</h5>
          <h3 class="font-weight-bolder text-center mb-1">
            {{ totalPropostas }}
          </h3>
          <!-- chart -->
          <vue-apex-charts height="50" :options="statisticsProfit.chartOptions" :series="statisticsProfit.series" />
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card body-class="pb-50">
          <h5>Valor médio das propostas</h5>
          <h3 class="font-weight-bolder text-center mb-1">
            {{ valorMedio }}
          </h3>
          <!-- chart -->
          <vue-apex-charts height="50" :options="statisticsProfit.chartOptions" :series="statisticsProfit.series" />
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
  import { BCard, BCol, BRow } from 'bootstrap-vue'
  import VueApexCharts from 'vue-apexcharts'
  import { $themeColors } from '@themeConfig'
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarValor } from '@/libs/utils'

  const $trackBgColor = '#EBEBEB'

  export default {
    components: {
      BCard,
      BCol,
      BRow,
      VueApexCharts,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 25,
          currentPage: 1,
          dataInicial: '',
          dataFinal: '',
        },
        totalPropostas: 1,
        isBusy: false,
        valorMedio: 1,
        statisticsProfit: {
          series: [
            {
              data: [0, 20, 10, 30, 10, 20],
            },
          ],
          chartOptions: {
            chart: {
              type: 'line',
              toolbar: {
                show: false,
              },
              zoom: {
                enabled: false,
              },
            },
            grid: {
              borderColor: $trackBgColor,
              strokeDashArray: 5,
              xaxis: {
                lines: {
                  show: false,
                },
              },
              yaxis: {
                lines: {
                  show: false,
                },
              },
              padding: {
                top: -30,
                bottom: -10,
              },
            },
            stroke: {
              width: 3,
            },
            colors: [$themeColors.info],
            markers: {
              size: 2,
              colors: $themeColors.info,
              strokeColors: $themeColors.info,
              strokeWidth: 2,
              strokeOpacity: 1,
              strokeDashArray: 0,
              fillOpacity: 1,
              discrete: [
                {
                  seriesIndex: 0,
                  dataPointIndex: 5,
                  fillColor: '#ffffff',
                  strokeColor: $themeColors.info,
                  size: 5,
                },
              ],
              shape: 'square',
              radius: 2,
              hover: {
                size: 3,
              },
            },
            xaxis: {
              labels: {
                show: false,
                style: {
                  fontSize: '0px',
                },
              },
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
            },
            yaxis: {
              show: false,
            },
            tooltip: {
              x: {
                show: false,
              },
            },
          },
        },
      }
    },
    mounted() {
      this.carregarGrid()
      this.$root.$on('atualizarGraficos3', (item) => {
        this.userData = this.$jwtDecode(localStorage.getItem('userData')).userData
        this.carregarGrid()
      })
    },
    methods: {
      carregarGrid() {
        this.isBusy = true
        if (this.userData.institutoSelecionado) {
          useJwt
            .pesquisar('dashboards/getTotalizadoresPropostasTelaInicial', {
              institutoId: Number(this.userData.institutoSelecionado),
            })
            .then((response) => {
              this.totalPropostas = formatarValor(response.data.valorTotalPropostas)
              this.valorMedio = formatarValor(response.data.valorMedio)
            })
            .catch((error) => {
              console.error(error)
            })
            .finally(() => {
              this.isBusy = false
            })
        }
      },
    },
  }
</script>
